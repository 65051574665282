/**
 * add
 * 为了【新增】功能而做的公用函数，方便mixin组合进入具体的【新增型views】
 * @author mabo
 */
export default {
    data() {
        return {
            originalForm: null,
            isLoading: false,
            isMock: false,
        }
    },
    methods: {
        //标段下拉
        handleBidChage(value) {
            let self = this;
            self.form.bidId = value[1]; //所属标段
            if (self.mapData && self.form.bidId !== "") {
                self.mapData.move2center = true;
                self.$api_hw.common_getBidInfo({
                    id: self.form.bidId
                }).then(res => {
                    self.log('mk', res.bidCoordinateScope);
                    try {
                        self.mapData.scope = Array.isArray(res.bidCoordinateScope) ? res.bidCoordinateScope : JSON.parse(res.bidCoordinateScope);
                    } catch (e) {
                        self.mapData.scope = null;
                    }
                }).catch(() => {
                    self.mapData.scope = null;
                });

            }
        },
        onSubmitAndNext() {
            this.onSubmit(true);
        },
        //关闭当前页面
        onClose(toPath) {
            if (toPath && typeof toPath == "string")
                // for 标签页
                BUS.$emit(BUSEVENT.CLOSE_NAV, [this.$route, toPath]);
            else {
                BUS.$emit(BUSEVENT.CLOSE_NAV, this.$route);
            }
            // for 弹窗
            this.$emit("onClose");
        },
        //提交表单
        async onSubmit(isGoNext) {
            let self = this;
            if (self.isLoading) return; //防止重复提交
            self.$refs.form.validate(async valid => {
                if (!valid) return;
                self.isLoading = true;
                try {
                    await self.submit();
                    self.$notify({
                        title: "成功",
                        message: `${self.nav[0].name}成功`,
                        type: "success"
                    });
                    BUS.$emit(BUSEVENT.makeEvent("refresh", self.$route.query.from));
                    if (isGoNext !== true) {
                        self.onClose("../" + self.$route.query.from);
                    } else {
                        self.form = self.originalForm;
                        // resetForm(self.form);
                        self.$refs["form"].resetFields();
                        //重置地图
                        if (this.mapData) {
                            this.mapData.lnglat = this.mapData.polygonScope = this.mapData.lineScope = this.mapData.scope = null;
                            this.mapData.lineLength = this.mapData.polygonArea = 0;
                        }
                    }
                } catch (e) {
                    //console.error(e);
                } finally {
                    self.isLoading = false;
                }
            });
        },
    },
    computed: {
        visitedViews() {
            return this.$store.state.tagsView.visitedViews;
        },
        routes() {
            return this.$store.state.permission.routes;
        }
    },
    created() {
        this.originalForm = this.form ? this.$_.cloneDeep(this.form) : {};
        this.log(this.originalForm);
    },
    activated() {
        //重置表单
        this.$refs["form"].resetFields();
        //重置地图
        if (this.mapData) {
            this.mapData.lnglat = this.mapData.polygonScope = this.mapData.lineScope = this.mapData.scope = null;
            this.mapData.lineLength = this.mapData.polygonArea = 0;
        }
    }
}


/////////////////////////////////////////辅助函数//////////////////
/**
 * 重置form，使其最后一层为空对象{}或者空数组
 * @param {object} form 
 */
function resetForm(form) {
    switch (getDepth(form)) {
        case 1:
            form = {};
            break
        case 2:
        case 3:
            for (let k in form) {
                form[k] = (form[k] === null || form[k] === '' || form[k] === undefined) ? null : typeof form[k] == "number" ? null : typeof form[k] == "string" ? null : Array.isArray(form[k]) ? [] : {};
            }
            break;
        default:
            form = {};
            break
    }
}

/**
 * 获取JSON的最大层级
 * @param {object} form 
 */
function getDepth(form) {
    //不是对象不计算深度
    if (typeof form != "object") return 0;
    //如果值为空直接退出
    if (!form) return 0;
    //详细计算
    let depth = 0;
    let i = 0;
    let childMaxDepth = 0;
    for (let k in form) {
        if (form.hasOwnProperty(k)) {
            i++;
            let cd = getDepth(form[k]);
            if (cd > childMaxDepth) {
                childMaxDepth = cd;
            }

        }
    }
    if (i > 0) depth = 1;
    depth += childMaxDepth;

    return depth;
}

/////////////////////////////////////////////////////////////////////////