<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main">
        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          :inline="true"
          :label-position="$config.labelPosition"
          :label-width="$config.labelWidth"
          size="mini"
        >
          <div class="form-label-base">{{nav[0].name}}</div>
          <el-form-item label="所属企业">
            <b>{{companyName}}</b>
            <!-- <el-input v-model="form.company" style="display:none;"></el-input> -->
          </el-form-item>

          <el-form-item label="车队名称" prop="teamName">
            <el-input v-model="form.teamName" placeholder="请输入" maxlength="18"></el-input>
          </el-form-item>

          <el-form-item label="车队负责人" prop="contacts">
            <el-input v-model="form.contacts" placeholder="请输入" maxlength="18"></el-input>
          </el-form-item>

          <el-form-item label="联系电话" prop="contactPhone">
            <el-input v-model="form.contactPhone" placeholder="手机号" maxlength="11"></el-input>
          </el-form-item>

          <el-form-item label="车队业务" prop="teamBusiness">
            <el-input v-model="form.teamBusiness" placeholder="请输入" maxlength="64"></el-input>
          </el-form-item>

          <el-form-item label="所属标段" prop="bidId">
            <ayl-cascader-for-bid v-model="form.bidId" @change="handleBidChage"></ayl-cascader-for-bid>
          </el-form-item>
        </el-form>
      </div>

      <div class="tac mt40px">
        <el-button type="success" class="btn-success-hw" @click="onSubmit">保存</el-button>
        <el-button class="btn-hw" @click="onSubmitAndNext">保存并录入下一条</el-button>
        <el-button type="danger" class="btn-danger" style="margin-left: 10px" @click="onClose">关闭</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import add from "@/mixins/add";
export default {
  mixins: [add],

  data() {
    return {
      nav: [{ name: "新增车队" }],
      company: null,
      companyName: null,
      form: {
        bidId: null,
        contactPhone: null,
        contacts: null,
        groupId: null,
        teamBusiness: null,
        teamName: null
      },
      rules: {
        // company: [
        //   {
        //     min: 2,
        //     max: 25,
        //     required: true,
        //     message: "请输入2-25位企业名称",
        //     trigger: "blur"
        //   },
        //   { validator: this.$validator.isContainBlank }
        // ],
        teamName: [
          {
            min: 2,
            max: 18,
            required: true,
            message: "请输入2-18位车队名称",
            trigger: "blur"
          },
          { validator: this.$validator.isContainBlank }
        ],
        contacts: [
          {
            max: 18,
            required: true,
            message: "请输入车队负责人",
            trigger: "blur"
          },
          { validator: this.$validator.isContainBlank }
        ],
        contactPhone: [
          {
            required: true,
            trigger: "blur",
            validator: this.$validator.validatePhone
          }
        ],
        teamBusiness: [
          {
            max: 64,
            required: true,
            message: "请输入车队业务",
            trigger: "blur"
          },
          { validator: this.$validator.isContainBlank }
        ],
        bidId: { required: true, message: "请选择标段", trigger: "change" }
      }
    };
  },
  methods: {
    /**
     * @summary【钩子函数，函数名固定】
     * @description 提交表单。此函数内，只需要专注操作表单数据this.form，不用管任何UI逻辑。
     * @author mabo
     */
    async submit() {
      let self = this;
      await self.$api_hw.fleetManagement_insert({
        record: _fixData(self.form)
      });
      function _fixData(d) {
        //数据格式转化
        // for (let key in d) {
        //   switch (key) {
        //   }
        // }
        d.company=self.company;
        self.log(d);
        return d;
      }
    }
  },
  async mounted() {
    let companyInfo = await this.$api_hw.common_getTenantCompanyInfo({});
    this.companyName = companyInfo.label;
    this.company = companyInfo.value;
  }
};
</script>

<style lang='sass' scoped>
.form-label-base
  border-left: 2px solid rgb(26, 188, 156)
  padding-left: 8px
</style>
